import BlogPostContainer from "./BlogPostContainer";

const Norwegen = () => {
    const blogPosts = [
        {
            date: "28.09.2024",
            title: "22. Tag",
            content: (
                <>
                    ein letztes "Hallo Zusammen" für diese Reise,
                    <br/><br/>
                    Wir sind heute ziemlich ohne Pause Richtung Schweiz gefahren. In Dänemark durfte eine Fahrt über den
                    Strand natürlich nicht fehlen. Nun stehen wir für heute Nacht auf einem Camping in Deutschland.
                    <br/><br/>
                    Zusammenfassung der Reise:
                    <br/><br/>
                    Schweiz - Deutschland - Dänemark - Schweden - Norwegen - Dänemark - Deutschland - Schweiz
                    <br/><br/>
                    Kilometer: ca. 7600km
                    <br/><br/>
                    Fahrzeit: ca. 6950min = 115h und 50min = 4 Tage und 19h und 50min
                    <br/><br/><br/>
                    Anstrengend aber sehr schön, so würde ich die Reise beschreiben. Morgen geht es noch an die letzten paar
                    Kilometer.
                    <br/><br/>
                    und damit bis zum nächsten Mal...
                    <br/>
                    die Heimkehrer
                </>
            ),
            images: [
                "/images/07_Norwegen/22.1.jpg",
                "/images/07_Norwegen/22.2.jpg",
                "/images/07_Norwegen/22.3.jpeg",
                "/images/07_Norwegen/22.4.jpg",
                "/images/07_Norwegen/22.5.jpeg",
            ]
        },
        {
            date: "27.09.2024",
            title: "21. Tag",
            content: (
                <>
                    Guten Abend aus Dänemark,
                    <br/><br/>
                    Heute sind wir am morgen nochmals nach Kristiansand gefahren. Dort haben wir zum Abschluss unserer Reise
                    noch einige Andenken gekauft. Danach haben wir uns darüber Gedanken gemacht was wir heute noch machen möchten.
                    Letztendlich haben wir uns dazu entschlossen wenn möglich heute schon die Fähre nach Hirtshals zu nehmen,
                    so haben wir ein wenig mehr Zeit für die Heimfahrt.
                    <br/><br/>
                    Uns so sitzen wir nun hier in Dänemark im Bulli auf einem Camping am Meer. Morgen geht es demnach schon
                    Richtung Schweiz, damit wir am Sonntag im Verlaufe des Tages zuhause ankommen.
                    <br/><br/>
                    Morgen gibt es noch den Abschlussbericht, da Sonntag nur noch ein Autobahnfahren durch Deutschland wird.
                    Für morgen werde ich mal die Stunden und Kilometer auf dieser Reise ausrechnen und auflisten.
                    <br/><br/>
                    die Grenzüberschreiter
                </>
            ),
            images: [
                "/images/07_Norwegen/21.1.jpg",
                "/images/07_Norwegen/21.2.jpeg",
                "/images/07_Norwegen/21.3.jpeg",
                "/images/07_Norwegen/21.4.jpeg",
                "/images/07_Norwegen/21.5.jpg",
                "/images/07_Norwegen/21.6.jpg",
                "/images/07_Norwegen/21.7.jpg",
                "/images/07_Norwegen/21.8.jpg",
            ]
        },
        {
            date: "26.09.2024",
            title: "20. Tag",
            content: (
                <>
                    God kveld oder Guten Abend
                    <br/><br/>
                    Heute sind wir direkt vom Schlafplatz nach Vennesla, Nördlich von Kristiansand gefahren. Dort haben wir einen
                    coolen Spaziergang in den Tømmerrenna, auf englisch "Lumber Slides" gemacht. Diese wurden früher
                    zum Transport von Holzstämmen gebraucht.
                    <br/><br/>
                    Am Anfang des Weges wird uns "Betreten auf eigene Gefahr" erklärt und doch waren wir nicht die einzigen
                    die sich auf den Weg gemacht haben. Das Spazieren durch diese alte Rinne ist mal was anderes und trotzdem
                    auf eine Art ziemlich lustig.
                    <br/><br/>
                    Sven als Holzversteher hat sich an einigen Stellen etwas gewundert, dass dieser Weg überhaupt noch
                    zugelassen ist und ob die bei der Restaurierung nichts von Rostfreiem Material gewusst haben.
                    <br/><br/>
                    Wir fanden es eine schöne Abwechslung zu den sonstigen Wanderungen die wir gemacht haben. Aus Zeitgründen
                    sind wir nicht bis zum Schluss gelaufen.
                    <br/><br/>
                    Nach diesem Ausflug ging es für uns auf unseren heutigen Stellplatz für die Nacht. Von hier sind wir
                    mit den ÖV nach Kristiansand gefahren und haben uns nach dem Schaufensterbummel ein feines Znacht gegönnt.
                    <br/><br/>
                    die Rinnenwanderer
                </>
            ),
            images: [
                "/images/07_Norwegen/20.1.jpg",
                "/images/07_Norwegen/20.2.jpg",
                "/images/07_Norwegen/20.3.jpeg",
                "/images/07_Norwegen/20.4.jpg",
                "/images/07_Norwegen/20.5.jpg",
                "/images/07_Norwegen/20.6.jpg",
                "/images/07_Norwegen/20.7.jpg",
                "/images/07_Norwegen/20.8.jpg",
                "/images/07_Norwegen/20.9.jpg",
            ]
        },
        {
            date: "25.09.2024",
            title: "19. Tag",
            content: (
                <>
                    Hallihallo,
                    <br/><br/>
                    Heute ging es direkt nach dem Aufstehen zum Preikestolen Parkplatz.
                    Obwohl die Wettervorhersage Regen und noch mehr Regen angezeigt hat, haben wir uns dazu
                    entschlossen die Wanderung zu machen. Wie erwartet war alle nass und der Weg über die Steine
                    ziemlich rutschig. Unterwegs war die Aussicht nicht allzu schlecht, da wir uns immer unterhalb der
                    Nebeldecke bewegt haben.
                    <br/>
                    Auf den letzten paar Höhenmeter sind wir aber leider direkt rein gewandert. Weder den Felsvorsprung
                    aus einiger Entfernung noch irgeneine Art der Aussicht war zu sehen.
                    <br/><br/>
                    Nach kalten und regnerischen 15 Minuten oben haben wir uns auf den Rückweg gemacht. Der Abstieg war um
                    einiges Ungemütlicher als der Aufstieg durch die rutschigen Steine.
                    <br/><br/>
                    Zurück beim Parkplatz gab es einen heisse Schokolade und eine wohlverdiente Pause.
                    <br/><br/>
                    Im Anschluss sind wir weiter Richtung Kristiansand gefahren und haben nach langer Suche einen
                    Übernachtungsplatz gefunden.
                    <br/><br/>
                    byebye und bis morgen,
                    <br/>
                    die Felsvorsprungserklimmer
                </>
            ),
            images: [
                "/images/07_Norwegen/19.1.jpg",
                "/images/07_Norwegen/19.2.jpg",
                "/images/07_Norwegen/19.3.jpg",
                "/images/07_Norwegen/19.4.jpg",
                "/images/07_Norwegen/19.5.jpg",
                "/images/07_Norwegen/19.6.jpg",
                "/images/07_Norwegen/19.7.jpg",
                "/images/07_Norwegen/19.8.jpg",
                "/images/07_Norwegen/19.9.jpeg",
                "/images/07_Norwegen/19.10.jpeg",
            ]
        },
        {
            date: "24.09.2024",
            title: "18. Tag",
            content: (
                <>
                    Heute sind wir es entspannt angegangen. Nach dem Aufstehen sind wir Richtung Stavanger gefahren.
                    Dort sind wir in Ruhe durch das Städtchen gebummelt. Es hatte einige deutsche Touris, da eines der "Mein Schiff"
                    Kreuzfahrtschiff angelegt hatte.
                    <br/><br/>
                    Danach sind wir bis zum Start unseres morgigen Abenteuers gefahren.
                    <br/><br/>
                    Morgen dann etwas mehr dazu.
                    <br/><br/>
                    die Schlenderer
                </>
            ),
            images: [
                "/images/07_Norwegen/18.1.jpg",
                "/images/07_Norwegen/18.2.jpg",
                "/images/07_Norwegen/18.3.jpg",
            ]
        },
        {
            date: "23.09.2024",
            title: "17. Tag",
            content: (
                <>
                    Guten Abend,
                    <br/><br/>
                    Heute sind wir immer weiter Südlich gefahren. Unterwegs haben wir uns einen Plan gemacht, was wir die
                    letzten Tage noch machen wollen.
                    <br/><br/>
                    Nachdem wir an unserem heutigen Schlafplatz angekommen sind ging es zu Fuss ins Dorf-/Stadtzentrum.
                    Wir haben uns  etwas umgesehen, haben ein Schläckisäckli zusammengestellt und ein Dessert für heute
                    Abend besorgt.
                    <br/><br/>
                    Morgen gehts weiter zu unserem nächsten Ziel, welches wir uns dann am Mittwoch vornehmen.
                    <br/><br/>
                    Bis morgen,
                    <br/>
                    die Schaufensterbummler
                </>
            ),
            images: [
                "/images/07_Norwegen/17.1.jpg",
                "/images/07_Norwegen/17.2.jpeg",
                "/images/07_Norwegen/17.3.jpeg",
                "/images/07_Norwegen/17.4.jpeg",
            ]
        },
        {
            date: "22.09.2024",
            title: "16. Tag",
            content: (
                <>
                    Hallo zusammen,
                    <br/><br/>
                    Heute in einer Woche ist die Reise schon um.
                    <br/><br/>
                    Zuerst ging es heute morgen von unserem Übernachtungsplatz Richtung Bergen. Wir haben uns für einen
                    Camping etwa 30 Minuten von Bergen entfernt entschieden. Nachdem wir uns einen Platz ausgesucht haben,
                    ging es an die Planung "wie kommen wir nach Bergen".
                    <br/><br/>
                    Zuerst einen Bus bis nach Arna Bahnhof und von dort mit dem Zug nach Bergen Hauptbahnhof. Innerhalb von 40 Minuten
                    sind wir angekommen.
                    <br/><br/>
                    Lets explore Bergen... Zu Fuss sind wir an den Hafen gelaufen, dort sind wir am Wasser entlang geschlendert und
                    in etwa jeden Souvenirshop schauen gegangen. Viel mehr Shops hatten heute an einem Sonntag nicht geöffnet.
                    Einen Abstecher in den Weihnachtsshop durfte natürlich auch nicht fehlen, auf drei Stockwerke alles Weihnachtszeug.
                    <br/><br/>
                    Heute haben wir es uns richtig gut gehen lassen. In einem schönen Restaurant haben wir Burger gegessen.
                    Danach durfte das Dessert natürlich auch nicht fehlen.
                    <br/><br/>
                    Nach unserer Rückreise mit den ÖV zum Camping, sind wir nun etwas am entspannen. Heute müssen wir noch
                    unsere nächsten Tage Planen, mal schauen wo es uns noch hinführt.
                    <br/><br/>
                    die Bergenentdecker
                </>
            ),
            images: [
                "/images/07_Norwegen/16.1.jpg",
                "/images/07_Norwegen/16.2.jpg",
                "/images/07_Norwegen/16.3.jpg",
                "/images/07_Norwegen/16.4.jpg",
                "/images/07_Norwegen/16.5.jpg",
                "/images/07_Norwegen/16.6.jpg",
            ]
        },
        {
            date: "21.09.2024",
            title: "15. Tag",
            content: (
                <>
                    Heute sind wir nach dem Aufstehen von Hellesylt Richtung Bergen gefahren. Unterwegs haben wir und für einen
                    Umweg entlang an einem der grössten Fjorde entschieden.
                    <br/><br/>
                    Wir sind einiges an Kilometern gefahren und haben die Landschaft genossen. Morgen geht es mit grosser Wahrscheinlichkeit
                    nach Bergen.
                    <br/><br/>
                    Schlaft gut,
                    <br/>
                    die Geniesser
                </>
            ),
            images: [
                "/images/07_Norwegen/15.1.jpg",
                "/images/07_Norwegen/15.2.jpg",
                "/images/07_Norwegen/15.3.jpg",
                "/images/07_Norwegen/15.4.jpg",
            ]
        },
        {
            date: "20.09.2024",
            title: "14. Tag",
            content: (
                <>
                    Guten Abend,
                    <br/><br/>
                    Heute morgen sind wir als erstes den Track zu Ende gefahren. Danach ging es Richtung Geiranger, da
                    der Trollstigen gesperrt ist mussten wir dazu aussen herum fahren. Gesagt getan, vor Ort haben wir
                    das Dörflein zu Fuss erkundet. Um 16:00 Uhr ging es mit der Autofähre auf den Geirangerfjord nach
                    Hellesylt. Hier stehen wir nun auf einem Camping.
                    <br/><br/>
                    Bis morgen,
                    <br/>
                    die Fjorderkunder
                </>
            ),
            images: [
                "/images/07_Norwegen/14.1.jpg",
                "/images/07_Norwegen/14.2.jpg",
                "/images/07_Norwegen/14.3.jpeg",
                "/images/07_Norwegen/14.4.jpeg",
                "/images/07_Norwegen/14.5.jpg",
                "/images/07_Norwegen/14.6.jpg",
                "/images/07_Norwegen/14.7.jpg",
                "/images/07_Norwegen/14.8.jpg",
                "/images/07_Norwegen/14.9.jpg",
                "/images/07_Norwegen/14.10.jpeg",
                "/images/07_Norwegen/14.11.jpeg",
            ]
        },
        {
            date: "19.09.2024",
            title: "13. Tag",
            content: (
                <>
                    Hallooo und Willkommen zu einer neuen Folge "Was haben wir heute gemacht?"
                    <br/><br/>
                    Direkt nach dem Aufstehen ging es auf unsere Wanderung, das Ziel: die Trollkirka Höhlen. Der Weg war
                    anstrengen aber machbar, wir sind aber eindeutig weniger Sportlich als die norwegischen Schüler auf
                    ihrem Schulausflug.<br/>
                    Oben angekommen sind wir direkt in die erste von drei Höhlen. Mit Taschenlampe und Stirnlampe sind wir
                    über Steine bis zum Wasserfall gelaufen/geklettert.
                    <br/><br/>
                    Die beiden anderen Höhlen haben wir nicht Besucht, da es in Höhle zwei zu viel Wasser hatte und Eingang
                    zu Höhle drei einigermassen schmal ist.
                    <br/><br/>
                    Nach unserer Rückkehr zum VW sind wir ca. 1.5h zum Startpunkt des nächsten Tracks gefahren. Uns gefällt dieser
                    Track sehr gut, er führt von einem Fjord in den anderen. Hier stehen wir nun auch für die Nacht, morgen
                    fahren wir ihn noch zu Ende bevor es zu weiteren Abenteuern geht.
                    <br/><br/>
                    Byebye und bis morgen mit "Was haben wir heute gemacht?"
                    <br/>
                    die Höhlenkletterer
                </>
            ),
            images: [
                "/images/07_Norwegen/13.1.jpg",
                "/images/07_Norwegen/13.2.jpg",
                "/images/07_Norwegen/13.3.jpg",
                "/images/07_Norwegen/13.4.jpeg",
                "/images/07_Norwegen/13.5.jpg",
                "/images/07_Norwegen/13.6.jpeg",
                "/images/07_Norwegen/13.7.jpeg"
            ]
        },
        {
            date: "18.09.2024",
            title: "12. Tag",
            content: (
                <>
                    Guten Abend miteinander,
                    <br/><br/>
                    Heute gings weiter Richtung Süden. Auf dem Weg sind wir über die Atlantik Road gefahren. Wie erwartet
                    war es auf den Bilder nicht dasselbe wie wenn man eine Drohne fliegen lässt. Da wir uns aber aufgrund der
                    Restriktionen und Vorgaben gegen die Mitnahme der Drohne entschieden haben, konnten wir die Bilder leider
                    nicht machen.
                    <br/><br/>
                    Heute hatten wir einigermassen viel Sonne am Nachmittag, eine gelungene Abwechslung zum Regen.
                    <br/><br/>
                    Nun stehen wir auf einem Wanderparkplatz um morgen direkt loslegen zu können.
                    <br/><br/>
                    die imAutoSitzenden
                </>
            ),
            images: [
                "/images/07_Norwegen/12.1.jpg",
                "/images/07_Norwegen/12.2.jpeg",
                "/images/07_Norwegen/12.4.jpeg",
                "/images/07_Norwegen/12.5.jpg",
                "/images/07_Norwegen/12.6.jpeg",
            ]
        },
        {
            date: "17.09.2024",
            title: "11. Tag",
            content: (
                <>
                    Hallo,
                    <br/><br/>
                    Heute war, wie gestern erwähnt, unser Fahrtag. Wir sind morgens relativ zeitig los und sind mehr
                    oder weniger durchgefahren mit einigen kleinen Stopps. Zuerst haben wir in Saltstraumen angehalten,
                    um das wirbelnde Wasser zu sehen, leider waren wir dazu zu früh dran. Weiter ging es mit einem Stopp
                    beim Arctic Circle Center am Polarkreis.
                    <br/><br/>
                    Viel mehr gibt es heute nicht zu erzählen.
                    <br/>
                    die Südwärtsfahrenden
                </>
            ),
            images: [
                "/images/07_Norwegen/11.1.jpg",
                "/images/07_Norwegen/11.2.jpeg",
                "/images/07_Norwegen/11.3.jpeg",
                "/images/07_Norwegen/11.4.jpg",
                "/images/07_Norwegen/11.5.jpg",
                "/images/07_Norwegen/11.6.jpg",
            ]
        },
        {
            date: "16.09.2024",
            title: "10. Tag",
            content: (
                <>
                    Welcome on Board,
                    <br/><br/>
                    Heute kommt das Tagebuch von der Fähre aus. Aber alles der Reihe nach...
                    <br/><br/>
                    Heute sind wir mit dem Ziel, bis ans Ende der Lofoten zu fahren, aufgestanden. Nach dem Losfahren
                    gab es eine Planänderung, da wir angesichts des Wetters (naaattüüüürlich bewölkt) keine Lust auf eine Wanderung hatten.
                    So sind wir den Fjorden entlang Richtung Lofotenende gefahren. Unterwegs dann plötzlich Sonne ;-)
                    Wir können uns nun vorstellen, warum die Lofoten so einen guten Ruf haben. Durch das Sonnenlicht ist
                    das Wasser wirklich türkis und zusammen mit den weissen Stränden hat es etwas von der Karibik. <br/>
                    Leider hat die Sonne nicht wirklich lange gegen die Wolken gewonnen, aber wir wollen uns ja nicht beschweren.
                    Immerhin hatten wir etwas Sonne auf den Lofoten, bevor wir abreisen.
                    <br/><br/>
                    Nach einer kurzen Rast im Nirgendwo gings nach Å i Lofoten, dem letzten Städtchen. Dort haben wir in einer
                    Steinofenbäckerei ein Brot gekauft, wir hatten Glück noch etwas zu ergattern. Danach sind wir ins Dorfcafé,
                    natürlich gab es eine Hot Chocolate und ein Gebäck.
                    <br/><br/>
                    Plan war eigentlich morgen Dienstag die Fähre zurück aufs Festland zu nehmen. Während unserer Kaffeepause
                    haben wir online geschaut wegen der Fähre und zu unserem Schock gesehen, dass die Fähre heute und
                    morgen online "Sold out" ist.
                    Online gab es nicht viele Infos, wie viele zur online Buchung freigegeben sind und wie viele Plätze den
                    "Anstehenden" Fahrzeugen ohne Buchung zur Verfügung stehen.
                    Wir haben bei der Reiseplanung mit Absicht die Fähre noch nicht gebucht:<br/>
                    a: weil es Nebensaison und gemäss allen Reisetipp Seiten keine Buchung notwendig ist
                    <br/>
                    b: weil wir uns noch nicht ganz sicher waren wann wir genau weiterreisen wollen
                    <br/><br/>
                    Nach dieser Entdeckung haben wir uns einen Schlachtplan ausgedacht:<br/>
                    Wir fahren zur Fähre und schauen mal, ob es schon Fahrzeuge hat. Falls ja, stellen wir uns ebenfalls schon
                    mal an. Dies mit der Idee, dass wenn wir auf der Fähre heute Abend nicht mehr Platz hätten, wir morgen als
                    eines der ersten Fahrzeuge ohne Reservation einen Platz bekommen. Da Nebensaison ist, gibt es nur zwei
                    Abfahren pro Tag.
                    <br/><br/>
                    So sind wir ziemlich früh also schon am Fährterminal angestanden und musste einige Stunden Zeit absitzen.
                    Also erst mal Wohnungsputz und auf-/umräumen. Danach noch etwas Schule und etwas nicht machen.
                    <br/><br/>
                    Nun sitzen wir in der Fähre, die uns in ca. 3.5h nach Bødo bringt. Von dort geht es südwärts. Die nächsten
                    beiden Tage werden also wieder Fahrtage um Kilometer gutzumachen.
                    <br/><br/>
                    So das wars für heute
                    <br/>
                    die Fährenwartenden
                </>
            ),
            images: [
                "/images/07_Norwegen/10.1.jpg",
                "/images/07_Norwegen/10.2.jpeg",
                "/images/07_Norwegen/10.3.jpg",
                "/images/07_Norwegen/10.4.jpeg",
                "/images/07_Norwegen/10.5.jpeg",
                "/images/07_Norwegen/10.6.jpg",
                "/images/07_Norwegen/10.7.jpg",
                "/images/07_Norwegen/10.8.jpg",
                "/images/07_Norwegen/10.9.jpg",
                "/images/07_Norwegen/10.10.jpeg",
                "/images/07_Norwegen/10.11.jpg",
            ]
        },
        {
            date: "15.09.2024",
            title: "9. Tag",
            content: (
                <>
                    Hallo zusammen,
                    <br/><br/>
                    Heute mal etwas früher als sonst, da wir schon auf dem Camping sind.
                    <br/><br/>
                    Nach einer erholsamen Nacht sind wir um kurz vor neun los. Heute gings an unsere zweite Wanderung.
                    <br/><br/>
                    Los gings auf den Offersøykammen hoch. Gestartet sind wir von einem Parkplatz direkt am Meer. Von dort ging
                    es angenehm einigermassen flach auf den ersten Hügel. Zum Glück hatten wir Handschuhe und Mütze dabei, durch
                    den Wind war es ziemlich Kalt. Seitlich sind wir dann nach vorne Richtung Berg gelaufen.
                    Nach einiger Zeit, angekommen am Fusse des Berges ging es ziemlich Steil hoch bis zum Gipfel. Da es in den letzten
                    Tagen immer wieder geregnet hat war die gesamte Wanderung eine ziemlich Rutschige angelegenheit.
                    Oben angekommen war die Aussicht wieder sehr schön, leider hat die Sonne nicht ganz mitgespielt.
                    <br/><br/>
                    Nach unserer Wanderung sind wir zum Dorf Nusfjord gefahren. Nusfjord ist ein Fischerdörfchen mit den typischen
                    roten Fischerhütten.
                    <br/><br/>
                    Nun stehen wir für diese Nacht wieder auf einem Campingplatz. Hier könnte man Surfen aber uns ist es
                    momentan zu kalt dafür.
                    <br/><br/>
                    Einen schönen Abend wünschen wir euch,
                    <br/>
                    die vom Winde verwehten
                </>
            ),
            images: [
                "/images/07_Norwegen/9.1.jpg",
                "/images/07_Norwegen/9.2.jpg",
                "/images/07_Norwegen/9.3.jpeg",
                "/images/07_Norwegen/9.4.jpeg",
                "/images/07_Norwegen/9.5.jpg",
                "/images/07_Norwegen/9.6.jpeg",
                "/images/07_Norwegen/9.7.jpeg",
            ]
        },
        {
            date: "14.09.2024",
            title: "8. Tag",
            content: (
                <>
                    Guten Abend,
                    <br/><br/>
                    Ablauf von heute:
                    <br/>
                    - Henningsvær
                    <br/>
                    - an der Küste weiter Richtung Lofoten Ende
                    <br/>
                    - Wikingermuseum
                    <br/>
                    - Schlafplatz finden
                    <br/><br/>
                    So sind wir also losgefahren und haben uns nach Henningsvær begeben. Leider hat das Wetter nicht so super
                    mitgespielt und es hat geregnet und sehr stark gewindet. Zum aufwärmen haben wir uns eine heisse Schokolade
                    und eine Zimtschnecke gegönnt.
                    <br/><br/>
                    Nächster Halt das Lofotr Winkingermuseum. Dies war spannend aber wir sind beide nicht die grössten Museumsgänger.
                    Das Areal ist ziemlich gross und im Sommer sicher sehr schön zum Besuchen, jetzt in der Nebensaison haben
                    sie vieles bereits nicht mehr angeboten (Wikingerbootsfahrt, Pfeilbogen, Restaurants).
                    <br/><br/>
                    Nun stehen wir auf einem Stellplatz/Wanderparkplatz. Anfangs waren wir zu zweit, mittlerweile stehen ungefähr
                    sieben weitere Fahrzeuge hier. Zum Glück haben wir uns einen guten Platz gesichert.
                    <br/><br/>
                    Bis morgen,
                    <br/>
                    die Museumsbesucher oder so...
                </>
            ),
            images: [
                "/images/07_Norwegen/8.1.1.jpg",
                "/images/07_Norwegen/8.1.jpeg",
                "/images/07_Norwegen/8.2.jpeg",
                "/images/07_Norwegen/8.3.jpg",
                "/images/07_Norwegen/8.4.jpg",
                "/images/07_Norwegen/8.5.jpeg",
                "/images/07_Norwegen/8.6.jpeg",
                "/images/07_Norwegen/8.7.jpeg",
                "/images/07_Norwegen/8.8.jpg",
            ]
        },
        {
            date: "13.09.2024",
            title: "7. Tag",
            content: (
                <>
                    Hallihallo,
                    <br/><br/>
                    Heute wars ein entspannter Tag, nachdem wir gestern ziemlich spät noch auf Nordlichtjagt waren.
                    Die Nordlichter waren direkt beim VW zu sehen aber wir sind immer wieder nach draussen gegangen um noch einen
                    besseren Blick zu haben.
                    <br/><br/>
                    Das war der Grund wiso wir etwas länger geschlafen haben. Heute ging es los Richtung Lofoten. Um
                    nicht den gleichen Weg wie beim Hochfahren zu nehmen sind wir via Langøya und Hadseløya Richtung
                    Henningsvær. Von da haben wir eine Fähre nach Fiskebøl genommen.
                    <br/><br/>
                    Das Wetter war ziemlich wechselhaft mit überwiegend Regen. Wir sind trotzdem an der Küste entlang gefahren
                    anstelle der Hauptstrasse. So konnten wir etwas Lofoten sehen, sofern der Regen dies zugelassen hat.
                    <br/><br/>
                    Auf den Lofoten sind Stellplätze ziemlich schwer zu finden, aus diesem Grund haben wir uns erneut für einen
                    Camping entschieden.
                    <br/><br/>
                    Bis morgen und einen schönen Abend
                    <br/>
                    Die Lofotenankommer
                </>
            ),
            images: [
                "/images/07_Norwegen/7.1.jpg",
                "/images/07_Norwegen/7.2.jpeg",
                "/images/07_Norwegen/7.3.jpg",
                "/images/07_Norwegen/7.4.jpeg",
                "/images/07_Norwegen/7.5.jpeg",
                "/images/07_Norwegen/7.6.jpeg",
                "/images/07_Norwegen/7.7.jpeg",
                "/images/07_Norwegen/7.8.jpeg",
                "/images/07_Norwegen/7.9.jpeg",
            ]
        },
        {
            date: "12.09.2024",
            title: "6. Tag",
            content: (
                <>
                    Guten Abend miteinander,
                    <br/><br/>
                    Heute ging es am Morgen mit der Fähre nach Lødingen. Dnach sind wir bis an den nördlichsten Punkt unserer
                    Reise gefahren: Andenes.
                    <br/><br/>
                    Angekommen sind wir direkt auf den Wanderparkplatz gefahren, da wir heute die erste Wanderung der
                    Reise gemacht haben.
                    <br/>
                    Ziel: Måtind
                    <br/><br/>
                    Die Wanderung hat mit einem leichten Anstieg begonnen bevor der ars** Teil der Wanderung kam. Wir
                    mussten ziemlich Steil über Felsen hoch Wandern/Klettern. Oben angekommen war der restliche Teil einigermassen
                    angenehm. Nach 8.3km und 02:40h Wanderzeit (Zeit ohne Pausen) sind wir wieder beim Auto angekommen.
                    <br/><br/>
                    Nun stehen wir auf einem süssen Camping Platz mit direkter Sicht und direktem Zugang zum Strand. Morgen geht
                    es dann weiter mit unserem Eintreffen auf den Lofoten.
                    <br/><br/>
                    Die Wanderer
                </>
            ),
            images: [
                "/images/07_Norwegen/6.1.jpg",
                "/images/07_Norwegen/6.2.jpeg",
                "/images/07_Norwegen/6.3.jpeg",
                "/images/07_Norwegen/6.4.jpg",
                "/images/07_Norwegen/6.5.jpeg",
                "/images/07_Norwegen/6.6.jpeg",
                "/images/07_Norwegen/6.7.jpeg",
                "/images/07_Norwegen/6.8.jpg",
                "/images/07_Norwegen/6.9.jpg",
            ]
        },
        {
            date: "11.09.2024",
            title: "5. Tag",
            content: (
                <>
                    Welcome to Norway,
                    <br/><br/>
                    Heute sind wir auf direktem Weg quer zur Grenze nach Norwegen gefahren. Nun heisst es
                    "Welcome to your final destination!".
                    <br/><br/>
                    Unser Plan ist es, via Bognes nach Lødingen und dann weiter nach Andenes. Heute sind wir bis Bognes gekommen.
                    Morgen nehmen wir den Rest in Angriff.
                    <br/><br/>
                    Unterweges war der Szenenwechsel von Schweden nach Norwegen ziemlich gewaltig. Von einem Flachen Land zu
                    Hügel, Bergen und Fjorden.
                    <br/><br/>
                    Viel Spass beim Bilder durchschauen
                    <br/>
                    Die Grenzüberquerer
                </>
            ),
            images: [
                "/images/07_Norwegen/5.1.1.png",
                "/images/07_Norwegen/5.1.jpg",
                "/images/07_Norwegen/5.2.jpeg",
                "/images/07_Norwegen/5.3.jpeg",
                "/images/07_Norwegen/5.4.jpg",
                "/images/07_Norwegen/5.5.jpeg",
                "/images/07_Norwegen/5.6.jpg",
                "/images/07_Norwegen/5.7.jpeg",
            ]
        },
        {
            date: "10.09.2024",
            title: "4. Tag",
            content: (
                <>
                    Hallo und herzlich Willkommen zur neusten Etappe in "Was haben wir heute gemacht"!
                    <br/><br/>
                    Der Tag hat mit Regen begonnen, von den 21 Grad und Sonne gestern sind wir nun bei 10 Grad und Regen.
                    Aufgewacht, aufgeräumt und los.
                    <br/><br/>
                    T8 hat uns über einen Berg geführt. Die Strasse war ziemlich gerade und durch das Wetter in Nebel gehüllt.
                    Uns hat dieser Weg sehr gefallen. T9 führte fast parallel zur normalen Strasse auf der anderen Seite
                    des Flusses entlang.
                    <br/><br/>
                    Das beste heute:<br/>
                    Wir haben einige Rentiere gesehen.
                    <br/><br/>
                    Nach den Tracks sind wir nochmals einige Kilometer Nordwärts gefahren und stehen nun auf einem, von Schweizern
                    geführten, Campingplatz in Sandsjönäs. Wer kurz auf der Karte nachschaut, sieht das wir nun schon ziemlich
                    weit oben angelangt sind.
                    <br/><br/>
                    Morgen geht es weiter, der Plan ist noch nicht ganz abgeschlossen, da wir vielleicht noch einen Abstecher
                    machen wollen. <br/><br/>
                    Das wars für heute, tschüssi und bis morgen
                    <br/><br/>
                    Die Planschmieder
                </>
            ),
            images: [
                "/images/07_Norwegen/4.1.jpeg",
                "/images/07_Norwegen/4.2.jpeg",
                "/images/07_Norwegen/4.3.jpg",
                "/images/07_Norwegen/4.5.jpeg",
                "/images/07_Norwegen/4.6.jpeg",
                "/images/07_Norwegen/4.7.jpg",
                "/images/07_Norwegen/4.8.jpeg",
            ]
        },
        {
            date: "09.09.2024",
            title: "3. Tag",
            content: (
                <>
                    Hallooo,
                    <br/><br/>
                    Auch heute wieder früh los, um einiges an Kilometer zu machen auf unserem Weg zu den Lofoten.
                    Zuerst sind wir ca. 3.5h auf der E45 hochgefahren.
                    <br/><br/>
                    Danach ging es an die Tracks von Trackbook (kleine Nebenstrassen überland, meistens Schotter).Davon
                    haben wir zwei gemacht T6 und T7 und T8 haben wir angefangen. In den Fotos seht ihr in etwa wie das
                    aussieht. Wildtiere haben wir leider noch keine Gesehen, doch mitten im nirgendwo haben wir Kühe gefunden
                    (also fast wie bei uns).
                    <br/><br/>
                    Nun stehen wir wieder an einem See im nirgendwo auf Track Nummer 8. Morgen werden wir den und T9 in
                    Angriff nehmen bevor wir wieder Nordwärts düüüssssen.
                    <br/><br/>
                    Die Holperstrassenfahrer
                </>
            ),
            images: [
                "/images/07_Norwegen/3.1.jpg",
                "/images/07_Norwegen/3.2.jpeg",
                "/images/07_Norwegen/3.3.jpeg",
                "/images/07_Norwegen/3.4.jpeg",
                "/images/07_Norwegen/3.5.jpg",
                "/images/07_Norwegen/3.6.jpg",
                "/images/07_Norwegen/3.7.jpg",
                "/images/07_Norwegen/3.8.jpeg",
            ]
        },
        {
            date: "08.09.2024",
            title: "2. Tag",
            content: (
                <>
                    Guten Abend
                    <br/><br/>
                    Heute sind wir um 08:00 Uhr losgefahren mit Ziel Schweden. Über die Öresundsbron sind wir direkt nach
                    Malmö gefahren. Ein kurzer Abstecher zu Ikea durfte da natürlich auch nicht fehlen.
                    <br/><br/>
                    Weiter immer Richtung Norden. Nun stehen wir auf einem süssen Wanderparkplatz bei einem kleinen See.
                    <br/><br/>
                    Morgen fahren wir dann den ersten Off-Road Track. Das bringt hoffentlich etwas Abwechslung in unseren
                    Tag.
                    <br/><br/>
                    Wir wünschen einen schönen Abend.
                    <br/>
                    Eure Autoreisenden
                </>
            ),
            images: [
                "/images/07_Norwegen/2.1.jpeg",
                "/images/07_Norwegen/2.2.jpg",
                "/images/07_Norwegen/2.3.jpeg",
                "/images/07_Norwegen/2.4.jpeg",
                "/images/07_Norwegen/2.5.jpeg",
            ]
        },
        {
            date: "07.09.2024",
            title: "1. Tag",
            content: (
                <>
                    Hello und herzlich Willkommen auf das neuste Abenteuer
                    <br/>
                    <br/>
                    Kurz vorab ein paar allgemeine Infos. Wir sind in dieser Reise etwas Planloser als sonst Unterwegs.
                    Das kann also bedeuten, dass die Tagebucheinträge sehr unterschiedlich aufgeschaltet werden. Und wenn es
                    mal dazu kommt, dass wir kein Internet Zugang haben, kann es also auch 1-2 Tage dauern.
                    <br/><br/>
                    So nun aber los. Nach dem Hochzeit gestern in Deutschland sind wir heute gegen 09:00 Uhr losgefahren.
                    Immer weiter Richtung Hamburg hat es geheissen. Unterwegs haben wir dann die Route nach Schweden festgelegt.
                    <br/><br/>
                    bis nach Puttgarden und von da mit der Fähre nach Rødby. Hier sind wir nun in Dänemark nach ca. 10h Autofahrt.
                    <br/><br/>
                    Morgen wirds sicher nochmals ein fast reiner Autotag.
                    <br/>
                    Und damit wünsche ich eine gute Nacht.
                    <br/><br/>
                    Bis morgen, <br/>
                    Die Deutschland hinter sich lassenden Reisenden
                </>
            ),
            images: [
                "/images/07_Norwegen/1.1.jpg",
                "/images/07_Norwegen/1.2.jpg",
                "/images/07_Norwegen/1.3.jpeg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Norwegen</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Norwegen;